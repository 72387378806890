import { format } from "date-fns"

export function capitalize<T extends string>(s: T): T {
  if (!s) return "" as T

  return (s.charAt(0).toUpperCase() + s.slice(1)) as T
}

export function possessive(s: string | null): string {
  if (!s) return ""

  if (s.endsWith("s")) {
    return `${s}'`
  }

  return `${s}'s`
}

export function formatDate(date: Date): string {
  return format(date, "yyyy/MM/dd")
}
