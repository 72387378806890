export type ValueOf<T> = T[keyof T]

export type ValidatorFunction = (value: any) => string[]

export type NotReadonly<T> = { -readonly [key in keyof T]: T[key] }

export type MappedTuple<
  T extends readonly any[],
  U extends string | number | bigint | boolean | null | undefined
> = {
  readonly [K in keyof T]: `${U}${T[K]}`
}

type UnionToIntersection<U> = (
  U extends unknown ? (arg: U) => 0 : never
) extends (arg: infer I) => 0
  ? I
  : never

type LastInUnion<U> = UnionToIntersection<
  U extends unknown ? (x: U) => 0 : never
> extends (x: infer L) => 0
  ? L
  : never

export type UnionToTuple<T, Last = LastInUnion<T>> = [T] extends [never]
  ? []
  : [Last, ...UnionToTuple<Exclude<T, Last>>]

export const TypedArrayMap = <T extends readonly any[], U>(
  tuple: T,
  fn: (value: T[number]) => U
) => {
  const res = tuple.map(fn)
  return res as UnionToTuple<(typeof res)[number]>
}

export const TypedObjectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as (keyof T)[]
