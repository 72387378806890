import { z } from "zod"
import {
  FUNDING_TYPE_OPTIONS,
  INDUSTRY_OPTIONS,
  INVESTOR_HIGHLIGHTS_OPTIONS,
  INVESTOR_TYPE_OPTIONS,
  SIGNAL_REGION,
  SIGNAL_REGION_OPTIONS,
} from "~/consts/signals"
import { numericFilterSchema, setFilterSchema } from "~/utils/signal"

export type InvestorsDBFilters = z.infer<typeof investorsFiltersValidation>

const investorOverviewFiltersSchema = z
  .object({
    // TODO: Add CompanySize when the DB type is corrected
    // CompanySize: z.array(
    //   z.enum(
    //     Object.keys(COMPANY_SIZE_OPTIONS) as [CompanySize, ...CompanySize[]]
    //   )
    // ),
    Highlights: setFilterSchema(z.enum(INVESTOR_HIGHLIGHTS_OPTIONS)),
    types: setFilterSchema(z.enum(INVESTOR_TYPE_OPTIONS)),
    // foundedYear: numericFilterSchema({
    //   defaultValue: NUMERIC_OPERATORS.GTE,
    //   extraConstraints: (z) => z.lte(new Date().getFullYear()),
    // }),
  })
  .partial()

const investorFinancialsFiltersSchema = z
  .object({
    stages: setFilterSchema(z.enum(FUNDING_TYPE_OPTIONS)),
    targetIndustries: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    nInvestments: numericFilterSchema(),
    nLeadInvestments: numericFilterSchema(),
    nExits: numericFilterSchema(),
    nFunds: numericFilterSchema(),
  })
  .partial()

export const investorLocationFiltersSchema = z
  .object({
    HQLocation: z.array(z.string()),
    HQRegion: z.array(z.enum(SIGNAL_REGION)),
  })
  .partial()

export const investorsFiltersValidation = investorOverviewFiltersSchema
  .merge(investorFinancialsFiltersSchema)
  .merge(investorLocationFiltersSchema)

export const investorsAdvancedFiltersSchema = z.object({
  Overview: z.object({
    Overview: investorOverviewFiltersSchema,
  }),
  Location: z.object({
    Location: investorLocationFiltersSchema,
  }),
  "Investor Details": z.object({
    "Investor Details": investorFinancialsFiltersSchema,
  }),
})

export const investorsQuickFiltersSchema = z
  .object({
    Highlights: setFilterSchema(z.enum(INVESTOR_HIGHLIGHTS_OPTIONS)),
    HQRegion: z.array(z.enum(SIGNAL_REGION_OPTIONS)),
    types: setFilterSchema(z.enum(INVESTOR_TYPE_OPTIONS)),
  })
  .partial()

export type InvestorsQuickFilters = z.infer<typeof investorsQuickFiltersSchema>
