import { differenceInMonths, differenceInWeeks, endOfWeek, sub } from "date-fns"
import { z } from "zod"
import { DATE_RANGE_OPTIONS } from "./signal"

import { formatDate } from "./string/format"

export const signalDateFilterSchema = z.union([
  z.nativeEnum(DATE_RANGE_OPTIONS),
  z.tuple([z.string(), z.string()]),
])

export type SignalDateFilter = z.infer<typeof signalDateFilterSchema>

export const getSignalDate = (signalDate: Date, short = false) => {
  const today = sub(
    endOfWeek(new Date(), {
      weekStartsOn: 6,
    }),
    {
      weeks: 1,
    }
  )

  const date = endOfWeek(new Date(signalDate), {
    weekStartsOn: 6,
  })

  const diffWeeks = differenceInWeeks(today, date)
  const diffMonths = differenceInMonths(today, date)

  if (diffWeeks <= 0) {
    return `${short ? "T" : "Featured t"}his week`
  }

  if (diffWeeks === 1) {
    return `${short ? "L" : "Featured l"}ast week`
  }

  if (short) {
    if (diffMonths === 0) {
      return `This month`
    }

    if (diffMonths === 1) {
      return `Last month`
    }

    return `${diffMonths} months ago`
  }

  return `Featured ${formatDate(new Date(signalDate))}`
}
