import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import { z, ZodString, ZodTypeAny } from "zod"
import { isDateValid } from "./isDateValid"
import { isOneOf } from "./isOneOf"
import { ValueOf } from "./types"
import { isNullish, Values } from "./values"

export const NUMERIC_OPERATORS = {
  GTE: "gte",
  GT: "gt",
  EQUALS: "equals",
  LT: "lt",
  LTE: "lte",
} as const

export function numericFilterSchema<TOmitBetween extends boolean = false>({
  defaultValue = NUMERIC_OPERATORS.GTE,
  extraConstraints = (num) => num,
  allowNegatives,
  omitBetween,
}: {
  defaultValue?: ValueOf<typeof NUMERIC_OPERATORS>
  extraConstraints?: (num: z.ZodNumber) => z.ZodNumber
  allowNegatives?: boolean
  omitBetween?: TOmitBetween
} = {}) {
  const zodNumberSchema = extraConstraints(z.number().int())
  const zodNumberNegativeConstraint = allowNegatives
    ? zodNumberSchema
    : zodNumberSchema.nonnegative()

  const tupleSchema = z.tuple([
    z.nativeEnum(NUMERIC_OPERATORS).default(() => defaultValue),
    zodNumberNegativeConstraint,
  ])

  const unionSchema = z.union([
    z.tuple([
      z.nativeEnum(NUMERIC_OPERATORS).default(() => defaultValue),
      zodNumberNegativeConstraint,
    ]),
    z
      .tuple([zodNumberNegativeConstraint, zodNumberNegativeConstraint])
      .refine((tuple) => tuple[0] < tuple[1], {
        message: "The first number must be less than the second number",
      }),
  ])

  type TReturn = TOmitBetween extends true
    ? typeof tupleSchema
    : typeof unionSchema

  if (omitBetween) {
    return tupleSchema as TReturn
  }

  return unionSchema as TReturn
}

export const TEXT_OPERATORS = [
  "contains", // ILIKE
  "not.contains", // NOT ILIKE
  "equals", // aka "in", element-wise exact match
  "not.equals", // aka "not in", element-wise exact non-match
] as const

export const ALL_OPERATORS = [
  ...Object.values(NUMERIC_OPERATORS),
  ...TEXT_OPERATORS,
] as const

const zodTextOperatorEnum = z.enum(TEXT_OPERATORS)

export const textFilterSchema = z.tuple([
  zodTextOperatorEnum.default(
    () => "contains" as z.infer<typeof zodTextOperatorEnum>
  ),
  z.array(z.string()).nonempty(),
])

export const descriptionFilterSchema = z.tuple([
  z.enum(["contains", "not.contains"]).default(() => "contains" as const),
  z.array(z.string()).nonempty(),
])

export const TIME_FRAME_OPTIONS = ["days", "weeks", "months", "years"] as const
export const DATE_OPERATORS = ["before", "after", "equals"] as const

export const DATE_RANGE_OPTIONS = {
  THIS_WEEK: "This week",
  LAST_WEEK: "Last week",
  PAST_30_DAYS: "Past 30 days",
  PAST_90_DAYS: "Past 90 days",
  PAST_180_DAYS: "Past 180 days",
  PAST_360_DAYS: "Past 360 days",
} as const

export type DateRangeOption = Values<typeof DATE_RANGE_OPTIONS>

export const allDateRangeOptions =
  Object.values<DateRangeOption>(DATE_RANGE_OPTIONS)

export const zodDateSchema = z.custom<string>((val) => {
  return typeof val === "string" ? isDateValid(val) : false
})

export const dateBeforeAfterSchema_DEPRECATED = z.tuple([
  z.enum(DATE_OPERATORS),
  zodDateSchema,
])

export const dateRangeSchema = z
  .tuple([z.string().nullable(), z.string().nullable()])
  .refine(
    ([start, end]) =>
      // This is meant to accept old format of ["after" | "before" | "equals", date], but without showing it in the filter
      // New way of using it is through the range tab, by leaving empty start or end for before or after, respectively
      (isOneOf(start, DATE_OPERATORS) && isDateValid(end)) ||
      isDateValid(start) ||
      isDateValid(end),
    {
      message: "Invalid date",
    }
  )
  // Then transform it to the new format
  .transform(([start, end]) => {
    if (isOneOf(start, DATE_OPERATORS)) {
      switch (start) {
        case "after":
          return [end, null]
        case "before":
          return [null, end]
        case "equals":
          return [end, end]
      }
    }
    return [start, end]
  })

export const dateRollingRangeSchema = z.tuple([
  z.number().int().positive(),
  z.enum(TIME_FRAME_OPTIONS),
])

const dateDefaultSchema = z.nativeEnum(DATE_RANGE_OPTIONS)

export const dateFilterSchema = z.union([
  dateDefaultSchema,
  dateRangeSchema,
  dateRollingRangeSchema,
])

export const AND_OR_OPERATORS = ["OR", "AND"] as const
export const AND_OR_SCHEMA = z
  .enum(AND_OR_OPERATORS)
  .default(() => "OR" as const)

export type AndOrOperator = (typeof AND_OR_OPERATORS)[number]

export const andOrSchema = <T extends ZodTypeAny>(zodType: T) => {
  return z.union([
    z.tuple([AND_OR_SCHEMA, zodType]),
    z.tuple([AND_OR_SCHEMA, zodType]),
  ])
}

export const setFilterSchema = <Item extends ZodTypeAny = ZodString>(
  item?: Item
) => andOrSchema(z.array(item ?? z.string()))

export const booleanFilterSchema = z.boolean().nullable()

const fieldTitleMap: Record<string, string | JSX.Element> = {
  talent_id: "Specter - Talent Signal ID",
  strategic_id: "Specter - Strategic Signal ID",
  AnnouncementDelayMonths: "Announcement Delay (Months)",
  annualRevenueEstimate: "Annual Revenue Estimate (in USD)",
  BounceRate3MonthsGrowth: "Bounce Rate - 3 Months Growth",
  CategoryGroups: "Category Groups",
  CompanyName: "Company Name",
  CrunchbaseURL: "Crunchbase - URL",
  Employees2MonthsGrowth: "Employees - 2 Months Growth (%)",
  Employees3MonthsGrowth: "Employees - 3 Months Growth (%)",
  Employees4MonthsGrowth: "Employees - 4 Months Growth (%)",
  Employees5MonthsGrowth: "Employees - 5 Months Growth (%)",
  Employees6MonthsGrowth: "Employees - 6 Months Growth (%)",
  EmployeesMonthlyGrowth: "Employees - Monthly Growth (%)",
  FacebookURL: "Facebook - URL",
  FoundedDate: "Founded Date",
  FundingRounds: "Funding Rounds",
  GooglePlay2MonthsReviewsGrowth: "Google Play - 2 Months Reviews Growth",
  GooglePlay3MonthsReviewsGrowth: "Google Play - 3 Months Reviews Growth",
  GooglePlay4MonthsReviewsGrowth: "Google Play - 4 Months Reviews Growth",
  GooglePlay5MonthsReviewsGrowth: "Google Play - 5 Months Reviews Growth",
  GooglePlay6MonthsReviewsGrowth: "Google Play - 6 Months Reviews Growth",
  GooglePlayAppID: "Google Play - App ID",
  GooglePlayInstalls: "Google Play - Installs",
  GooglePlayMonthlyReviewsGrowth: "Google Play - Monthly Reviews Growth",
  GooglePlayRating: "Google Play - Rating",
  GooglePlayReviews: "Google Play - Reviews",
  GooglePlayURL: "Google Play - URL",
  G2Rating: "G2 - Rating",
  G2TotalReviews: "G2 - Total Reviews",
  G2Medals: "G2 - Medals",
  G2ProductReviews: "G2 - Product Reviews",
  G2JSONData: "G2 - Data",
  HasNewHighlights: "New Signals",
  HasNewFundingHighlights: "New Funding Signals",
  HasNewGrowthHighlights: "New Growth Signals",
  HotCompanies: "Hot Companies 🔥",
  HotCompaniesString: "Hot Companies",
  HotSignals: "Hot Signals 🔥",
  HotSignalsString: "Hot Signals",
  HQLocation: "HQ Location",
  HQRegion: "HQ Region",
  ITSpend: "IT Spend (in USD)",
  IndustryOG: "Industry",
  Instagram2MonthsFollowersGrowth: "Instagram - 2 Months Followers Growth",
  Instagram3MonthsFollowersGrowth: "Instagram - 3 Months Followers Growth",
  Instagram4MonthsFollowersGrowth: "Instagram - 4 Months Followers Growth",
  Instagram5MonthsFollowersGrowth: "Instagram - 5 Months Followers Growth",
  Instagram6MonthsFollowersGrowth: "Instagram - 6 Months Followers Growth",
  InstagramFollowers: "Instagram - Followers",
  InstagramFollowing: "Instagram - Following",
  InstagramMonthlyFollowersGrowth: "Instagram - Monthly Followers Growth",
  InstagramURL: "Instagram - URL",
  InvestorHighlights: "Highlights",
  LastFundingAmount: "Last Funding Amount (in USD)",
  LinkedIn2MonthsFollowersGrowth: "LinkedIn - 2 Months Followers Growth",
  LinkedIn3MonthsFollowersGrowth: "LinkedIn - 3 Months Followers Growth",
  LinkedIn4MonthsFollowersGrowth: "LinkedIn - 4 Months Followers Growth",
  LinkedIn5MonthsFollowersGrowth: "LinkedIn - 5 Months Followers Growth",
  LinkedIn6MonthsFollowersGrowth: "LinkedIn - 6 Months Followers Growth",
  LinkedIn: "LinkedIn",
  LinkedInFollowers: "LinkedIn - Followers",
  LinkedInMonthlyFollowersGrowth: "LinkedIn - Monthly Followers Growth",
  LinkedInURL: "LinkedIn - URL",
  LinkedInURLTalent: "LinkedIn - URL (Talent)",
  NewHighlights: "New Signals",
  NumberOfFounders: "Number of Founders",
  NumberOfFundingRounds: "Number of Funding Rounds",
  NumberOfInvestors: "Number of Investors",
  NumberOfPatents: "Number of Patents",
  NumberOfTechnologies: "Number of Technologies",
  NumberOfTrademarks: "Number of Trademarks",
  PagesPerVisit3MonthsGrowth: "Pages per Visit - 3 Months Growth",
  PagesPerVisit: "Pages per Visit",
  PastPosition: "Past Positions",
  Rank: "Specter Rank",
  SessionDuration3MonthsGrowth: "Session Duration (s) - 3 Months Growth",
  SessionDuration: "Session Duration (s)",
  SimilarWebsitesSimilarity: "Similar Websites and Similarity",
  "StratintelMulti.numberOfSignals": "Number of Signals (Total)",
  "StratintelMulti.signalCountPast30d": "Number of Signals - Past 30 days",
  "StratintelMulti.signalCountPast90d": "Number of Signals - Past 90 days",
  "StratintelMulti.signalCountPast180d": "Number of Signals - Past 180 days",
  "StratintelMulti.signalCountPast360d": "Number of Signals - Past 360 days",
  "StratintelMulti.signalCountPast720d": "Number of Signals - Past 720 days",
  "StratintelMulti.allSignalIds": "All Signals",
  SubIndustry: "Sub-Industry",
  TotalAppDownloads2MonthsGrowth: "Total App Downloads - 2 Months Growth (%)",
  TotalAppDownloads3MonthsGrowth: "Total App Downloads - 3 Months Growth (%)",
  TotalAppDownloads4MonthsGrowth: "Total App Downloads - 4 Months Growth (%)",
  TotalAppDownloads5MonthsGrowth: "Total App Downloads - 5 Months Growth (%)",
  TotalAppDownloads6MonthsGrowth: "Total App Downloads - 6 Months Growth (%)",
  TotalAppDownloads: "Total App Downloads",
  TotalAppDownloadsMonthlyGrowth: "Total App Downloads - Monthly Growth (%)",
  TotalFundingAmount: "Total Funding Amount (in USD)",
  TrustpilotData: "Trustpilot - Rating",
  TrustpilotReviews: "Trustpilot - Reviews",
  TrustpilotTotalReviews: "Trustpilot - Total Reviews",
  TrustpilotRating: "Trustpilot - Rating",
  TrustpilotJSONData: "Trustpilot - Data",
  Twitter2MonthsFollowersGrowth: "Twitter - 2 Months Followers Growth",
  Twitter3MonthsFollowersGrowth: "Twitter - 3 Months Followers Growth",
  Twitter4MonthsFollowersGrowth: "Twitter - 4 Months Followers Growth",
  Twitter5MonthsFollowersGrowth: "Twitter - 5 Months Followers Growth",
  Twitter6MonthsFollowersGrowth: "Twitter - 6 Months Followers Growth",
  TwitterFFRatio: "Twitter - F/F Ratio",
  TwitterFollowers: "Twitter - Followers",
  TwitterMonthlyFollowersGrowth: "Twitter - Monthly Followers Growth",
  TwitterURL: "Twitter - URL",
  TwitterURLTalent: "Twitter - URL (Talent)",
  WebVisits2MonthsGrowth: "Web Visits - 2 Months Growth (%)",
  WebVisits3MonthsGrowth: "Web Visits - 3 Months Growth (%)",
  WebVisits4MonthsGrowth: "Web Visits - 4 Months Growth (%)",
  WebVisits5MonthsGrowth: "Web Visits - 5 Months Growth (%)",
  WebVisits6MonthsGrowth: "Web Visits - 6 Months Growth (%)",
  WebVisitsMonthlyGrowth: "Web Visits - Monthly Growth (%)",
  WebsitePopularityRank2MonthsGrowth:
    "Website Popularity Rank - 2 Months Growth",
  WebsitePopularityRank3MonthsGrowth:
    "Website Popularity Rank - 3 Months Growth",
  WebsitePopularityRank4MonthsGrowth:
    "Website Popularity Rank - 4 Months Growth",
  WebsitePopularityRank5MonthsGrowth:
    "Website Popularity Rank - 5 Months Growth",
  WebsitePopularityRank6MonthsGrowth:
    "Website Popularity Rank - 6 Months Growth",
  WebsitePopularityRankMonthlyGrowth:
    "Website Popularity Rank - Monthly Growth",
  acquiredBy: "Acquired By",
  acquiredDate: "Acquired Date",
  acquiredName: "Acquiree Name",
  acquiredOn: "Acquisition Date",
  acquired: "Acquiree Name",
  acquirer: "Acquirer Name",
  acquisitionPrice: "Acquisition Price (in USD)",
  companySize: "Company Size",
  contactEmail: "Contact Email",
  crunchbaseUrl: "Crunchbase - URL",
  description: "Description",
  domain: "Domain",
  employeeCount: "Employee Count",
  employeeMonthlyGrowth1: "Employees - Monthly Growth (%)",
  employeeMonthlyGrowth2: "Employees - 2 Months Growth (%)",
  employeeMonthlyGrowth3: "Employees - 3 Months Growth (%)",
  employeeMonthlyGrowth4: "Employees - 4 Months Growth (%)",
  employeeMonthlyGrowth5: "Employees - 5 Months Growth (%)",
  employeeMonthlyGrowth6: "Employees - 6 Months Growth (%)",
  roles: "Roles",
  facebookUrl: "Facebook - URL",
  foundedYear: "Founded Date",
  founders: "Founders",
  foundersCount: "Number of Founders",
  fundingRounds: "Funding Rounds",
  fundingRoundsCount: "Number of Funding Rounds",
  funds: "Fund Details",
  g2_data: "G2 - Products",
  g2_rating_avg: "G2 - Rating",
  g2_total_reviews: "G2 - Total Reviews",
  githubUrl: "Github - URL",
  googlePlayAppId: "Google Play App ID",
  googlePlayInstalls: "Google Play - Installs",
  googlePlayRating: "Google Play - Rating",
  googlePlayReviews: "Google Play - Reviews",
  googlePlayReviewsMonthlyGrowth1: "Google Play - Monthly Reviews Growth",
  googlePlayReviewsMonthlyGrowth2: "Google Play - 2 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth3: "Google Play - 3 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth4: "Google Play - 4 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth5: "Google Play - 5 Months Reviews Growth",
  googlePlayReviewsMonthlyGrowth6: "Google Play - 6 Months Reviews Growth",
  googlePlayUrl: "Google Play - URL",
  growthStage: "Growth Stage",
  hqLocation: "HQ Location",
  hqRegion: "HQ Region",
  iTunes2MonthsReviewsGrowth: "iTunes - 2 Months Reviews Growth",
  iTunes3MonthsReviewsGrowth: "iTunes - 3 Months Reviews Growth",
  iTunes4MonthsReviewsGrowth: "iTunes - 4 Months Reviews Growth",
  iTunes5MonthsReviewsGrowth: "iTunes - 5 Months Reviews Growth",
  iTunes6MonthsReviewsGrowth: "iTunes - 6 Months Reviews Growth",
  iTunes: "iTunes",
  iTunesAppID: "iTunes - App ID",
  iTunesMonthlyReviewsGrowth: "iTunes - Monthly Reviews Growth",
  iTunesRating: "iTunes - Rating",
  iTunesReviews: "iTunes - Reviews",
  iTunesURL: "iTunes - URL",
  id: "Id",
  industry: "Industry",
  instagramFollowers: "Instagram - Followers",
  instagramFollowersMonthlyGrowth1: "Instagram - Monthly Followers Growth",
  instagramFollowersMonthlyGrowth2: "Instagram - 2 Months Followers Growth",
  instagramFollowersMonthlyGrowth3: "Instagram - 3 Months Followers Growth",
  instagramFollowersMonthlyGrowth4: "Instagram - 4 Months Followers Growth",
  instagramFollowersMonthlyGrowth5: "Instagram - 5 Months Followers Growth",
  instagramFollowersMonthlyGrowth6: "Instagram - 6 Months Followers Growth",
  instagramFollowing: "Instagram - Following",
  instagramUrl: "Instagram - URL",
  investors: "Investors",
  investorCount: "Number of Investors",
  investorsCount: "Number of Investors",
  ipoDetails: "IPO Details",
  itSpend: "IT Spend (in USD)",
  itunesAppId: "iTunes App ID",
  itunesRating: "iTunes - Rating",
  itunesReviews: "iTunes - Reviews",
  itunesReviewsMonthlyGrowth1: "iTunes - Monthly Reviews Growth",
  itunesReviewsMonthlyGrowth2: "iTunes - 2 Months Reviews Growth",
  itunesReviewsMonthlyGrowth3: "iTunes - 3 Months Reviews Growth",
  itunesReviewsMonthlyGrowth4: "iTunes - 4 Months Reviews Growth",
  itunesReviewsMonthlyGrowth5: "iTunes - 5 Months Reviews Growth",
  itunesReviewsMonthlyGrowth6: "iTunes - 6 Months Reviews Growth",
  itunesUrl: "iTunes - URL",
  lastFundingAmount: "Last Funding Amount (in USD)",
  lastFundingDate: "Last Funding Date",
  lastFundingType: "Last Funding Type",
  linkedinFollowers: "LinkedIn - Followers",
  linkedinFollowersMonthlyGrowth1: "LinkedIn - Monthly Followers Growth",
  linkedinFollowersMonthlyGrowth2: "LinkedIn - 2 Months Followers Growth",
  linkedinFollowersMonthlyGrowth3: "LinkedIn - 3 Months Followers Growth",
  linkedinFollowersMonthlyGrowth4: "LinkedIn - 4 Months Followers Growth",
  linkedinFollowersMonthlyGrowth5: "LinkedIn - 5 Months Followers Growth",
  linkedinFollowersMonthlyGrowth6: "LinkedIn - 6 Months Followers Growth",
  linkedinUrl: "LinkedIn - URL",
  moneyRaised: "Money Raised (in USD)",
  raisedAmount: "Money Raised (in USD)",
  month: "Month",
  companyName: "Company Name",
  new: "New Companies",
  news: "News",
  nInvestments: "Number of Investments",
  nLeadInvestments: "Number of Lead Investments",
  nExits: "Number of Exits",
  nFunds: "Number of Funds",
  operatingStatus: "Operating Status",
  otherDomains: "Other Domains",
  patentsCount: "Number of Patents",
  phoneNumber: "Phone Number",
  postMoneyValuation: "Post Money Valuation (in USD)",
  preMoneyValuation: "Pre Money Valuation (in USD)",
  primaryRole: "Primary Role",
  rank: "Rank",
  seniority: "Level of Seniority",
  sharePrice: "Share Price (in USD)",
  socialTrafficBreakdown: "Social Traffic Breakdown",
  specterId: "Specter - ID",
  specter_id: "Specter - Organization ID",
  specter_url: "Specter - URL",
  specter_source: "Source - CRM",
  status: "Operating Status",
  stockExchangeSymbol: "Stock Exchange",
  subIndustry: "Sub-industry",
  tags: "Tags",
  technologies: "Active Technologies",
  technologiesCount: "Number of Technologies",
  totalAppDownloads: "Total App Downloads",
  totalAppDownloadsMonthlyGrowth1: "Total App Downloads - Monthly Growth (%)",
  totalAppDownloadsMonthlyGrowth2: "Total App Downloads - 2 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth3: "Total App Downloads - 3 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth4: "Total App Downloads - 4 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth5: "Total App Downloads - 5 Months Growth (%)",
  totalAppDownloadsMonthlyGrowth6: "Total App Downloads - 6 Months Growth (%)",
  totalFundingAmount: "Total Funding Amount (in USD)",
  trademarksCount: "Number of Trademarks",
  trustpilot_data: "Trustpilot - Rating",
  "trustpilot_data.review_count": "Trustpilot - Total Reviews",
  twitterFollowers: "Twitter - Followers",
  twitterFollowersMonthlyGrowth1: "Twitter - Monthly Followers Growth",
  twitterFollowersMonthlyGrowth2: "Twitter - 2 Months Followers Growth",
  twitterFollowersMonthlyGrowth3: "Twitter - 3 Months Followers Growth",
  twitterFollowersMonthlyGrowth4: "Twitter - 4 Months Followers Growth",
  twitterFollowersMonthlyGrowth5: "Twitter - 5 Months Followers Growth",
  twitterFollowersMonthlyGrowth6: "Twitter - 6 Months Followers Growth",
  twitterUrl: "Twitter - URL",
  valuationPrice: "Valuation Price (in USD)",
  webBounceRate: "Bounce Rate",
  webBounceRateMonthlyGrowth3: "Bounce Rate - 3 Months Growth",
  webCountryBreakdown: "Country Breakdown",
  webOrganicSearchPercentage: "Organic Search Percentage",
  webPagesPerVisit: "Pages per Visit",
  webPagesPerVisitMonthlyGrowth3: "Pages per Visit - 3 Months Growth",
  webPaidSearchPercentage: "Paid Search Percentage",
  webPopularityRank: "Website Popularity Rank",
  webPopularityRankMonthlyGrowth1: "Website Popularity Rank - Monthly Growth",
  webPopularityRankMonthlyGrowth2: "Website Popularity Rank - 2 Months Growth",
  webPopularityRankMonthlyGrowth3: "Website Popularity Rank - 3 Months Growth",
  webPopularityRankMonthlyGrowth4: "Website Popularity Rank - 4 Months Growth",
  webPopularityRankMonthlyGrowth5: "Website Popularity Rank - 5 Months Growth",
  webPopularityRankMonthlyGrowth6: "Website Popularity Rank - 6 Months Growth",
  webSessionDuration: "Session Duration (s)",
  webSessionDurationMonthlyGrowth3: "Session Duration (s) - 3 Months Growth",
  webSimilarWebsitesSimilarity: "Similar Websites and Similarity",
  webTopCountry: "Top Country",
  webTrafficSources: "Traffic Sources",
  webVisits: "Web Visits",
  webVisitsMonthlyGrowth1: "Web Visits - Monthly Growth (%)",
  webVisitsMonthlyGrowth2: "Web Visits - 2 Months Growth (%)",
  webVisitsMonthlyGrowth3: "Web Visits - 3 Months Growth (%)",
  webVisitsMonthlyGrowth4: "Web Visits - 4 Months Growth (%)",
  webVisitsMonthlyGrowth5: "Web Visits - 5 Months Growth (%)",
  webVisitsMonthlyGrowth6: "Web Visits - 6 Months Growth (%)",
  website: "Website",
  wentPublicOn: "IPO Date",
  Description: "Keywords In Description",
  strategic_description: "Description",
  LastActivityDate: "Last Activity Date",
  inCRM: "In My CRM",
  "My CRM": "My CRM",
  types: "Investor Type",
  stages: "Investment Stage",
  listId: "Companies In List",
  b2x: "Customer Focus",
}

/**
 * Returns a renderable version of the title
 */
export const getSignalFieldTitle = (field: string): JSX.Element | string => {
  if (fieldTitleMap[field]) {
    return fieldTitleMap[field]
  }

  return titleCase(sentenceCase(field))
}

/**
 * Export an explicit string version of the title
 */
export const getSignalFieldTitleString = (field: string): string => {
  if (fieldTitleMap[`${field}String`]) {
    return fieldTitleMap[`${field}String`] as string
  }

  if (fieldTitleMap[field]) {
    return fieldTitleMap[field] as string
  }

  return titleCase(sentenceCase(field))
}

/**
 * Given a set of operators, put 'equals' and 'not.equals' first
 * and then include the rest
 */
export function equalsOperatorsFirst(operators: string[]): string[] {
  const output: string[] = []
  const copy: string[] = [...operators]

  const equalIndex = operators.indexOf("equals")
  const notEqualIndex = operators.indexOf("not.equals")

  if (!isNullish(equalIndex)) output.push(operators[equalIndex])
  if (!isNullish(notEqualIndex)) output.push(operators[notEqualIndex])

  return [
    ...output,
    ...copy.filter((elem) => !["equals", "not.equals"].includes(elem)),
  ]
}

export const OperatingStatusEnum = {
  active: "active",
  acquired: "acquired",
  closed: "closed",
  ipo: "ipo",
} as const

export type OperatingStatus =
  (typeof OperatingStatusEnum)[keyof typeof OperatingStatusEnum]

export const OPERATING_STATUS_LABEL = {
  [OperatingStatusEnum.active]: "Active",
  [OperatingStatusEnum.acquired]: "Acquired",
  [OperatingStatusEnum.closed]: "Closed",
  [OperatingStatusEnum.ipo]: "IPO",
} as const
